require('./bootstrap');
require('alpinejs');

import intlTelInput from 'intl-tel-input';

let jsonpAdapter = require('axios-jsonp');

const attachErrorMessageAfterElement = function(message, input, level) {

    if(level == undefined) level = 1;

    const p = document.createElement("p");
    p.innerText = message

    p.classList.add('tmp-error-el');
    p.classList.add('mt-2');
    p.classList.add('text-red-500');
    p.classList.add('text-xs');
    p.classList.add('italic');

    let ref = input;
    for (let i = 1; i <= level; i++) {
        ref = ref.parentElement;
    }

    ref.appendChild(p);
}

if(document.getElementById("grid-phone")){

    //document.addEventListener('load', () => {

        const input = document.getElementById("grid-phone");
        const iti = intlTelInput(input, {
            // any initialisation options go here
            // preferredCountries : ["FR", "BE"],
            hiddenInput: "phone",
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.min.js",
            separateDialCode: false,
            initialCountry: "auto",
            geoIpLookup: function(callback) {
                window.axios({
                    url: 'https://ipinfo.io/?token=844425e00b6c41',
                    adapter: jsonpAdapter,
                }).then((resp) => {
                    let location = (resp && resp.data && resp.data.country) ? resp.data.country : "fr"
                    callback(location)
                });
            },
        });

        input.addEventListener("countrychange", function() {
            //console.log(iti.getSelectedCountryData());
        });

        input.addEventListener('focus', () => {
            if(document.querySelector('.tmp-error-el'))
                document.querySelector('.tmp-error-el').remove();
        });

        input.addEventListener('blur', () => {
            if(!iti.isValidNumber()) {
                attachErrorMessageAfterElement('This phone number is invalid', input, 2);
            }
        });

    //});
}
